var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t("Pick Up")) + " "), _c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(_vm.pickUp.length))])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "hover": "",
      "responsive": "",
      "items": _vm.pickUp,
      "fields": _vm.fieldspengiriman
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "head(alamat)",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t("Alamat Pickup & Waktu Pickup "))), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "title": _vm.$t('Atur Masal Waktu Pickup'),
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.aturmass();
            }
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1)], 1), _c('b-modal', {
          ref: "aturmassModal",
          attrs: {
            "size": "xl",
            "id": "aturmass-modal",
            "title": "Atur Massal Pickup",
            "hide-footer": ""
          }
        }, [_c('b-table', {
          attrs: {
            "items": _vm.computedProviders,
            "fields": [{
              key: 'logisticsProviderName',
              label: 'Logistics Provider Name'
            }, {
              key: 'addresses',
              label: 'Addresses'
            }, {
              key: 'pickUpTimeList',
              label: 'Pickup Time List'
            }],
            "striped": "",
            "hover": "",
            "responsive": "sm"
          },
          scopedSlots: _vm._u([{
            key: "cell(addresses)",
            fn: function (data) {
              return [_c('b-form-select', {
                staticClass: "mb-2",
                attrs: {
                  "options": data.item.addressesOptions
                },
                on: {
                  "change": function ($event) {
                    return _vm.updateSelected(data.index, 'selectedAddress', _vm.selectedAddresses[data.index]);
                  }
                },
                model: {
                  value: _vm.selectedAddresses[data.index],
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedAddresses, data.index, $$v);
                  },
                  expression: "selectedAddresses[data.index]"
                }
              })];
            }
          }, {
            key: "cell(pickUpTimeList)",
            fn: function (data) {
              return [_c('b-form-select', {
                staticClass: "mb-2",
                attrs: {
                  "options": data.item.pickupTimeOptions
                },
                on: {
                  "change": function ($event) {
                    return _vm.updateSelected(data.index, 'selectedPickupTime', _vm.selectedPickupTimes[data.index]);
                  }
                },
                model: {
                  value: _vm.selectedPickupTimes[data.index],
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedPickupTimes, data.index, $$v);
                  },
                  expression: "selectedPickupTimes[data.index]"
                }
              })];
            }
          }])
        }), _c('b-button', {
          on: {
            "click": _vm.applySelections
          }
        }, [_vm._v("Terapkan")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedginee,
            callback: function ($$v) {
              _vm.selectedginee = $$v;
            },
            expression: "selectedginee"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(pesanan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', [_c('b-img', {
          staticStyle: {
            "width": "15px"
          },
          attrs: {
            "src": _vm.getImageUrl(item.channel),
            "title": item.channel
          }
        })], 1), _vm._v(" " + _vm._s(item.externalOrderId)), _c('br'), _vm._v(" " + _vm._s(item.orderStatus) + " ")];
      }
    }, {
      key: "cell(kurir)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null)), _c('br'), _vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null) + " ")];
      }
    }, {
      key: "cell(alamat)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-select', {
          scopedSlots: _vm._u([{
            key: "first",
            fn: function () {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null,
                  "disabled": ""
                }
              }, [_vm._v("Pilih salah satu")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: item.selected,
            callback: function ($$v) {
              _vm.$set(item, "selected", $$v);
            },
            expression: "item.selected"
          }
        }, [_vm._l(item.addresses, function (option) {
          return [_c('b-form-select-option', {
            attrs: {
              "value": option
            }
          }, [_vm._v(" " + _vm._s(option.address) + " ")])];
        })], 2), item.selected ? _c('b-form-select', {
          scopedSlots: _vm._u([{
            key: "first",
            fn: function () {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null,
                  "disabled": ""
                }
              }, [_vm._v("Pilih salah satu")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: item.selected2,
            callback: function ($$v) {
              _vm.$set(item, "selected2", $$v);
            },
            expression: "item.selected2"
          }
        }, [_vm._l(item.selected.pickupTimeList, function (option) {
          return [_c('b-form-select-option', {
            attrs: {
              "value": option.pickupTimeId
            }
          }, [_vm._v(" " + _vm._s(option.timeText !== "" ? option.timeText : _vm.formatTimestamp(option.pickupStartTime).slice(0, 5) + " - " + _vm.formatTimestamp(option.pickupEndTime).slice(0, 5) + " " + _vm.formatTimestamp(option.pickupStartTime).slice(6)) + " ")])];
        })], 2) : _vm._e()];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.aturpengirimansingle(item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Arrange Shipment")) + " ")]), _c('br')];
      }
    }])
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Drop Off"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t("Drop Off")) + " "), _c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(_vm.dropOff.length))])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.dropOff,
      "fields": _vm.fieldspengiriman
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedginee,
            callback: function ($$v) {
              _vm.selectedginee = $$v;
            },
            expression: "selectedginee"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref8) {
        var index = _ref8.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(pesanan)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.externalOrderId) + " ")];
      }
    }, {
      key: "cell(kurir)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null)), _c('br'), _vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null)), _c('br'), _c('br')];
      }
    }, {
      key: "cell(outbondorder)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('b-alert', {
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }), _vm._l(item.items, function (hem) {
          return [_c('b-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "bg-variant": "transparent",
              "no-body": ""
            }
          }, [_c('b-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            attrs: {
              "md": "9"
            }
          }, [_c('b-card-text')], 1)], 1)], 1)];
        })];
      }
    }, {
      key: "cell(status_bayar)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [item.status_bayar == 'lunas' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("LUNAS")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("BELUM LUNAS")])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.aturpengirimansingle(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.$t("Arrange Shipment")) + " ")], 1), _c('br'), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.printdoc(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PrinterIcon"
          }
        }), _vm._v(" Print Label ")], 1), _c('br'), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.getCustomerFrame(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "BoxIcon"
          }
        }), _vm._v(" Process to Warehouse ")], 1), item.transaction_type == 'jasa' && item.status != 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Pilih Terapis',
            expression: "'Pilih Terapis'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.modalTerapis(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1) : _vm._e(), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran Manual",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasiawal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), item.status_bayar != 'lunas' && item.payment_option == 'pg_midtrans' ? _c('b-button', {
          attrs: {
            "title": "Cek Pembayaran Otomatis",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasipg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Manual Ship"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t("Manual Ship")) + " "), _c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(_vm.manualShip.length))])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.dropOff,
      "fields": _vm.fieldspengiriman
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedginee,
            callback: function ($$v) {
              _vm.selectedginee = $$v;
            },
            expression: "selectedginee"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref16) {
        var index = _ref16.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(pesanan)",
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_vm._v(" " + _vm._s(item.externalOrderId) + " ")];
      }
    }, {
      key: "cell(kurir)",
      fn: function (_ref18) {
        var item = _ref18.item;
        return [_vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null)), _c('br'), _vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null)), _c('br'), _c('br')];
      }
    }, {
      key: "cell(outbondorder)",
      fn: function (_ref19) {
        var item = _ref19.item;
        return [_c('b-alert', {
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }), _vm._l(item.items, function (hem) {
          return [_c('b-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "bg-variant": "transparent",
              "no-body": ""
            }
          }, [_c('b-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            attrs: {
              "md": "9"
            }
          }, [_c('b-card-text')], 1)], 1)], 1)];
        })];
      }
    }, {
      key: "cell(status_bayar)",
      fn: function (_ref20) {
        var item = _ref20.item;
        return [item.status_bayar == 'lunas' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("LUNAS")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("BELUM LUNAS")])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref21) {
        var item = _ref21.item;
        return [_c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.aturpengirimansingle(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.$t("Arrange Shipment")) + " ")], 1), _c('br'), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.printdoc(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PrinterIcon"
          }
        }), _vm._v(" Print Label ")], 1), _c('br'), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.getCustomerFrame(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "BoxIcon"
          }
        }), _vm._v(" Process to Warehouse ")], 1), item.transaction_type == 'jasa' && item.status != 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Pilih Terapis',
            expression: "'Pilih Terapis'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.modalTerapis(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1) : _vm._e(), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function (_ref22) {
        var item = _ref22.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran Manual",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasiawal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), item.status_bayar != 'lunas' && item.payment_option == 'pg_midtrans' ? _c('b-button', {
          attrs: {
            "title": "Cek Pembayaran Otomatis",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasipg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }